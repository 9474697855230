/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'dropdown': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M13.756 7.796l-4.172 3.978A.844.844 0 019 12a.807.807 0 01-.578-.226L4.244 7.796A.74.74 0 014 7.24a.71.71 0 01.244-.55l.481-.458A.782.782 0 015.303 6c.231 0 .424.078.578.232L9 9.196l3.12-2.964A.782.782 0 0112.696 6c.227 0 .422.078.584.232l.481.459a.73.73 0 01.238.55.74.74 0 01-.244.555z" _fill="#fff"/>'
  }
})
